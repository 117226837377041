import { parallelLimit } from "async";
import dayjs from "dayjs";
import objectHash from "object-hash";
import { useEffect, useReducer, useRef } from "react";
import { showWarning } from "src/actions/app";
import {
  ForecastEndDateFailureResponse,
  ForecastEndDateRequest,
  ForecastEndDateResponse,
  ForecastImpressionFailureResponse,
  ForecastImpressionResponse,
  ForecastImpressionsRequest,
  getCampaignForecastEndDates,
  getCampaignForecastImpressions,
} from "src/api/campaigns";
import { getCampaignCPMsForShow } from "src/lib/campaigns";
import { getCampaignItemField } from "src/lib/campaign_item";
import { UnixTimeStamp } from "src/lib/date";
import { ICampaign, PublicShow, ICampaignItem, User } from "redcircle-types";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { useDispatchTS } from "./redux-ts";

const MAX_SHOWS_PER_STREAMULATOR_REQUEST = 20;
const MAX_NUM_OF_PARALLEL_REQUEST = 4;

/**
 * Reducer function to capture the state of the streamulator forecast impressions response
 */
export interface IMaxImpressionReducer<
  T = { [showUUID: string]: { impressions: number; streamulatorErrored: boolean } },
> {
  (
    state: { isLoading: boolean; error?: string; maxImpressions: T },
    action:
      | { type: "request" }
      | { type: "success"; maxImpressions: T }
      | { type: "failure"; error?: string; maxImpressions: T }
  ): { isLoading: boolean; error?: string; maxImpressions: T };
}

export const maxImpressionReducer: IMaxImpressionReducer = (state, action) => {
  switch (action.type) {
    case "request":
      return {
        ...state,
        isLoading: true,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        maxImpressions: action.maxImpressions,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action?.error,
        maxImpressions: action.maxImpressions,
      };

    default:
      return { ...state };
  }
};

/**
 * Reducer function to capture the state of the streamulator forecast min end dates response
 */
export interface IMinEndDatesReducer<T = ForecastEndDateResponse[keyof ForecastEndDateResponse]> {
  (
    state: {
      isLoading: boolean;
      error?: string;
      minEndDates: T;
    },
    action:
      | { type: "request" }
      | { type: "success"; minEndDates: T }
      | { type: "failure"; error?: string; minEndDates: T }
  ): {
    isLoading: boolean;
    error?: string;
    minEndDates: T;
  };
}

export const minEndDatesReducer: IMinEndDatesReducer = (state, action) => {
  switch (action.type) {
    case "request":
      return {
        ...state,
        isLoading: true,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        minEndDates: action.minEndDates,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error,
        minEndDates: action.minEndDates,
      };

    default:
      return { ...state };
  }
};

export const convertRespToImpressionsMap = (resp: ForecastImpressionResponse) => {
  const map = Object.entries(resp?.["resultsByShowUUID"] ?? {}).reduce(
    (mapper, curr) => {
      const [showUUID, val] = curr;

      mapper[showUUID] = {
        impressions: Object.values(val.impressionsByPosition ?? {}).reduce((accu, impressions) => {
          return accu + impressions;
        }, 0),
        streamulatorErrored: val.streamulatorErrored,
      };
      return mapper;
    },
    {} as Record<string, { impressions: number; streamulatorErrored: boolean }>
  );

  return map;
};

/**
 * Helper func to transform targeting positions into into correct format object for Forecast End Dates Request
 */
const constructFormattedRequestPositions = (targetingOptions: ICampaign["targetingOptions"]) => {
  const positions = Object.entries(targetingOptions).reduce(
    (accu, curr) => {
      const [key, val] = curr;

      if (val) {
        accu[key.toUpperCase() as keyof ForecastEndDateRequest["positions"]] = {};
      }
      return accu;
    },
    {} as ForecastEndDateRequest["positions"]
  );

  return positions;
};

/**
 * Helper function, hashes a V2 campaign Item to uniquely identify its properties and detect changes
 */
const hashCampaignItemForImpressionsForecast = (campaignItem: ICampaignItem) => {
  return objectHash(
    {
      uuid: campaignItem.uuid,
      startDate: getCampaignItemField("startAt", { campaignItem }),
      endDate: getCampaignItemField("endAt", { campaignItem }),
      positions: getCampaignItemField("targetingOptions", { campaignItem }),
      configs: getCampaignItemField("frequencyConfigs", { campaignItem }),
      recentEpisodesOnly: getCampaignItemField("recentEpisodesOnly", {
        campaignItem,
      }),
    },
    { algorithm: "sha1" }
  );
};

/**
 * Helper function, hashes a V2 campaign Item to uniquely identify its properties and detect changes
 */
const hashCampaignItemForEndDateForecast = (campaignItem: ICampaignItem) => {
  return objectHash(
    {
      uuid: campaignItem.uuid,
      startDate: getCampaignItemField("startAt", { campaignItem }),
      positions: getCampaignItemField("targetingOptions", { campaignItem }),
      configs: getCampaignItemField("frequencyConfigs", { campaignItem }),
      recentEpisodesOnly: getCampaignItemField("recentEpisodesOnly", {
        campaignItem,
      }),
    },
    { algorithm: "sha1" }
  );
};

/**
 * Builds a array of requests to fetch forecast end date. This helper function takes into
 * MAX_SHOWS_PER_STREAMULATOR_REQUEST, and omit requests that have already been cached
 * TODO: check for overrides on all draftItems when running streamulator
 */
const constructForecastEndDatesRequest = ({
  draftItems,
  campaign,
  budgets,
  startDate,
  mapCampaignItemUUIDToShow,
  cache,
}: {
  draftItems: ICampaignItem[];
  campaign: ICampaign;
  budgets: { [campaignItemUUID: string]: number };
  startDate: number;
  mapCampaignItemUUIDToShow: {
    [campaignItemUUID: string]: PublicShow;
  };

  cache: Partial<
    Record<
      string,
      Record<
        string,
        ForecastEndDateResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
      >
    >
  >;
}) => {
  const cacheKey = constructEndDateCacheKey({ campaign, startDate });

  const unCachedDraftItems = draftItems
    .filter((item) => !getCampaignItemField("pacing", { campaignItem: item, campaign })) // Will first filter for All items under non pacing V1 campaigns, or only singular non paced campaign items for V2 campaigns
    .filter((campaignItem) => {
      const showKey = constructEndDateShowKey({ budgets, campaignItem });

      const cachedVal = (cache[cacheKey] ?? {})[showKey];

      return typeof cachedVal?.estimatedEndTime !== "number";
    });

  const positions = constructFormattedRequestPositions(campaign.targetingOptions);

  const requests: ForecastEndDateRequest[] = [];
  let currReqIndex = 0;

  for (let i = 0; i < unCachedDraftItems.length; i++) {
    if (typeof requests?.[currReqIndex] !== "object") {
      requests.push({
        campaignItems: [],
        positions,
        startDate,
        recentEpisodesOnly: campaign?.recentEpisodesOnly,
        frequencyConfigs: campaign?.frequencyConfigs,
      });
    }

    const item = unCachedDraftItems[i];
    const publicShow = mapCampaignItemUUIDToShow[item.uuid];
    const formattedCPM = Object.entries(
      getCampaignCPMsForShow({ campaign, campaignItem: item, show: publicShow })
    ).reduce(
      (accu, curr) => {
        const [key, val] = curr;
        accu[key?.toUpperCase() as keyof ForecastEndDateRequest["campaignItems"][number]["cpm"]] =
          (val || 0) * 1000;

        return accu;
      },
      {} as ForecastEndDateRequest["campaignItems"][number]["cpm"]
    );

    const itemReq: ForecastEndDateRequest["campaignItems"][number] = {
      showUUID: item.showUUID,
      cpm: formattedCPM,
      budget: Math.round(budgets[item.uuid] * 1000),
    };

    // Attach targeting options override
    if (item.isV2 && item?.targetingOptions?.overridden) {
      itemReq.positionsOverride = constructFormattedRequestPositions(item.targetingOptions.value);
    }

    // Attach startDate override
    if (item.isV2 && item?.lifecycleSettings?.startAt?.overridden) {
      itemReq.startDateOverride = item?.lifecycleSettings?.startAt?.value;
    }

    // Attach recent episodes only override
    if (item.isV2 && item?.recentEpisodesOnly?.overridden) {
      itemReq.recentEpisodesOnlyOverride = item?.recentEpisodesOnly?.value;
    }

    // Attach frequency configs override
    if (item.isV2 && item.frequencyConfigs?.overridden) {
      itemReq.frequencyConfigsOverride = item?.frequencyConfigs?.value;
    }

    requests?.[currReqIndex]?.campaignItems?.push(itemReq);

    if (requests?.[currReqIndex]?.campaignItems.length >= MAX_SHOWS_PER_STREAMULATOR_REQUEST) {
      currReqIndex++;
    }
  }

  return requests;
};
const constructEndDateCacheKey = ({
  campaign,
  startDate,
}: {
  campaign?: ICampaign;
  startDate: number;
}) => {
  if (!campaign) return "";
  const positions = `${Object.keys(campaign.targetingOptions).sort().join("-")}`;
  const freqConfigsStr = Object.values(campaign.frequencyConfigs ?? {})
    .sort((a, b) => a.interval.localeCompare(b.interval))
    .map(({ interval, maxCount }) => `${interval}:${maxCount}`)
    .join(",");

  return campaign.isV2
    ? `${campaign.uuid}_${dayjs.unix(startDate)}` // still need to update key when user edits start date in scheduler
    : `${campaign.uuid}_${dayjs
        .unix(startDate)
        .format("MM/DD/YYYY")}_${positions}_${freqConfigsStr}_${campaign.recentEpisodesOnly}`;
};
const constructEndDateShowKey = ({
  budgets,
  campaignItem,
}: {
  budgets: { [campaignItemUUID: string]: number };
  campaignItem: ICampaignItem;
}) => {
  const hash = hashCampaignItemForEndDateForecast(campaignItem);

  return campaignItem?.isV2 ? `${hash}` : `${campaignItem.showUUID}_${budgets[campaignItem?.uuid]}`;
};

/**
 * Constructs an array of streamulator impression forecast requests. Takes into account cached
 * showUUIDs and only returns a request array for showUUIDs that are not cached
 */
const constructForecastImpressionsRequest = ({
  campaign,
  draftItems,
  startDate,
  endDate,
  cache,
}: {
  campaign: ICampaign;
  draftItems: ICampaignItem[];
  startDate: number;
  endDate: number;
  cache: Partial<
    Record<
      string,
      Record<
        string,
        ForecastImpressionResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
      >
    >
  >;
}) => {
  const cacheKey = constructImpressionsCacheKey({ campaign, startDate, endDate });

  const unCachedDraftItems = draftItems
    .filter((item) => getCampaignItemField("pacing", { campaignItem: item, campaign })) // Will first filter for All items under pacing V1 campaigns, or only singular non paced campaign items for V2 campaigns
    .filter((campaignItem) => {
      const showKey = constructImpressionsShowKey({ campaignItem });

      const cachedVal = (cache[cacheKey] ?? {})[showKey];

      return (
        typeof cachedVal?.streamulatorErrored !== "boolean" ||
        (typeof cachedVal?.streamulatorErrored === "boolean" &&
          cachedVal.streamulatorErrored === true)
      );
    });

  const positions = Object.entries(campaign.targetingOptions).reduce(
    (accu, curr) => {
      const [key, val] = curr;

      if (val) {
        accu[key.toUpperCase() as keyof ForecastImpressionsRequest["positions"]] = {};
      }
      return accu;
    },
    {} as ForecastImpressionsRequest["positions"]
  );

  const requests: ForecastImpressionsRequest[] = [];
  let currReqIndex = 0;

  for (let i = 0; i < unCachedDraftItems.length; i++) {
    if (typeof requests?.[currReqIndex] !== "object") {
      requests.push({
        campaignItems: [],
        positions,
        startDate,
        endDate,
        recentEpisodesOnly: campaign?.recentEpisodesOnly,
        frequencyConfigs: campaign?.frequencyConfigs,
      });
    }

    const item = unCachedDraftItems[i];
    const { showUUID } = item;

    const itemReq: ForecastImpressionsRequest["campaignItems"][number] = {
      showUUID,
    };

    // Attach targeting options override
    if (item.isV2 && item?.targetingOptions?.overridden) {
      itemReq.positionsOverride = constructFormattedRequestPositions(item.targetingOptions.value);
    }

    // Attach startDate override
    if (item.isV2 && item?.lifecycleSettings?.startAt?.overridden) {
      itemReq.startDateOverride = item?.lifecycleSettings?.startAt?.value;
    }

    // Attach end date override
    if (item.isV2 && item?.lifecycleSettings?.endAt?.overridden) {
      itemReq.endDateOverride = item?.lifecycleSettings?.endAt?.value;
    }

    // Attach recent episodes only override
    if (item.isV2 && item?.recentEpisodesOnly?.overridden) {
      itemReq.recentEpisodesOnlyOverride = item?.recentEpisodesOnly?.value;
    }

    // Attach frequency configs override
    if (item.isV2 && item?.frequencyConfigs?.overridden) {
      itemReq.frequencyConfigsOverride = item?.frequencyConfigs?.value;
    }

    requests?.[currReqIndex]?.campaignItems?.push(itemReq);

    if (requests?.[currReqIndex]?.campaignItems.length >= MAX_SHOWS_PER_STREAMULATOR_REQUEST) {
      currReqIndex++;
    }
  }

  return requests;
};
const constructImpressionsCacheKey = ({
  campaign,
  startDate,
  endDate,
}: {
  campaign?: ICampaign;
  startDate: number;
  endDate: number;
}) => {
  if (!campaign) return "";
  const format = "MM/DD/YYYY";
  const positions = `${Object.keys(campaign.targetingOptions).sort().join("-")}`;
  const freqConfigsStr = Object.values(campaign.frequencyConfigs ?? {})
    .sort((a, b) => a.interval.localeCompare(b.interval))
    .map(({ interval, maxCount }) => `${interval}:${maxCount}`)
    .join(",");

  return campaign.isV2
    ? `${campaign.uuid}_${dayjs.unix(startDate).format(format)}_${dayjs.unix(endDate).format(format)}`
    : `${campaign.uuid}_${dayjs.unix(startDate).format(format)}_${dayjs
        .unix(endDate)
        .format(format)}_${positions}_${freqConfigsStr}_${campaign.recentEpisodesOnly}`;
};

const constructImpressionsShowKey = ({ campaignItem }: { campaignItem: ICampaignItem }) => {
  const hash = hashCampaignItemForImpressionsForecast(campaignItem);

  return campaignItem?.isV2 ? `${hash}` : `${campaignItem?.showUUID}`;
};

/**
 * Helper Hook to encapsulate forecast timeline logic
 */
export const useForecastEndDates = ({
  user,
  campaign,
  draftItems,
  publicShows,
  budgets,
  startDate,
}: {
  user: User;
  campaign?: ICampaign;
  draftItems: ICampaignItem[];
  publicShows: PublicShowsReduxState;
  budgets: { [campaignItemUUID: string]: number };
  startDate: UnixTimeStamp;
}) => {
  const dispatch = useDispatchTS();

  const [minEndDatesState, dispatchMinEndDates] = useReducer<IMinEndDatesReducer>(
    minEndDatesReducer,
    { isLoading: false, minEndDates: {}, error: undefined }
  );

  const isPublicShowsLoaded =
    publicShows?.isLoading === false &&
    draftItems?.every(({ showUUID }) => publicShows?.[showUUID]?.uuid === showUUID);

  const isBudgetsEmpty = draftItems?.length > 0 && Object.keys(budgets)?.length === 0;

  const { mapCampaignItemUUIDToShow, mapShowToCampaignItem } = draftItems?.reduce(
    (accu, curr) => {
      const { showUUID, uuid } = curr;

      accu.mapCampaignItemUUIDToShow[uuid] = publicShows?.[showUUID] ?? {};

      accu.mapShowToCampaignItem[showUUID] = { ...curr };

      return accu;
    },
    { mapCampaignItemUUIDToShow: {}, mapShowToCampaignItem: {} } as {
      mapCampaignItemUUIDToShow: { [campaignItemUUID: string]: PublicShow };
      mapShowToCampaignItem: { [showUUID: string]: ICampaignItem };
    }
  );

  /**
   * Enables hook based on V1 campaign.pacing flag or V2 campaign items pacing flag
   */
  const isNonPacing = draftItems.some(
    (item) => !getCampaignItemField("pacing", { campaignItem: item, campaign })
  );

  /**
   * Need to capture when individual campaign Items update relevant info for impressions endpoint to fire, will do
   * this by grabbing relevant fields and hashing the object.
   */
  const draftItemsChangeSignal = campaign?.isV2
    ? objectHash(
        draftItems
          .sort((a, b) => a.uuid?.localeCompare(b.uuid))
          .reduce(
            (accu, curr) => {
              accu[curr.uuid] = {
                uuid: curr.uuid,
                startDate: getCampaignItemField("startAt", { campaignItem: curr }),
                positions: getCampaignItemField("targetingOptions", { campaignItem: curr }),
                configs: getCampaignItemField("frequencyConfigs", { campaignItem: curr }),
                recentEpisodesOnly: getCampaignItemField("recentEpisodesOnly", {
                  campaignItem: curr,
                }),
                pacing: getCampaignItemField("pacing", { campaignItem: curr }),
              };

              return accu;
            },
            {} as {
              [campaignItemUUID: string]: {
                uuid: string;
                startDate: number;
                positions: ICampaignItem["targetingOptions"];
                configs: ICampaignItem["frequencyConfigs"];
                recentEpisodesOnly: boolean;
                pacing: boolean;
              };
            }
          ),
        { algorithm: "sha1" }
      )
    : "";

  /**
   * Ensures streamulator runs on changes to the campaign start date, or changes to campaign items start date for V2 campaign items
   */
  const startDateChangeSignal = campaign?.isV2
    ? draftItems
        .sort((a, b) => a.uuid.localeCompare(b.uuid))
        .map((item) => item?.lifecycleSettings?.startAt?.value)
        .join("_")
        .concat(`_${startDate?.toString()}`)
    : startDate;

  // All data needed for starting requests
  const preReqDataIsReady =
    !isBudgetsEmpty &&
    isPublicShowsLoaded &&
    !!publicShows &&
    isNonPacing &&
    !!user &&
    !!campaign &&
    typeof startDate === "number" &&
    draftItems?.length > 0;

  const budgetTotal = Object.values(budgets ?? {}).reduce((accu, curr) => accu + curr, 0);
  const budgetLength = Object.keys(budgets ?? {}).length;

  const budgetChangeSignal = `${budgetTotal}_${budgetLength}`;

  const cache = useRef<
    Partial<
      Record<
        string,
        Record<
          string,
          ForecastEndDateResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
        >
      >
    >
  >({});
  const cacheKey = constructEndDateCacheKey({ campaign, startDate });

  /**
   * Request new forecasted timeline
   */
  useEffect(() => {
    if (preReqDataIsReady && !minEndDatesState.isLoading && cacheKey) {
      // Array of requests, already partitioned for MaxShowPerRequest limit, and cached
      // requests have been omitted
      const requests = constructForecastEndDatesRequest({
        campaign,
        draftItems,
        budgets,
        startDate,
        mapCampaignItemUUIDToShow,
        cache: cache.current,
      });

      const tasks = requests.map((request) => (callback: any) => {
        getCampaignForecastEndDates(request, user)
          .then((resp) => {
            return resp?.json().then((val) => {
              if (resp.status !== 200) {
                callback(null, {
                  status: resp.status,
                  val: val.details,
                  message: val.message,
                });
              } else {
                callback(null, { status: resp.status, val, message: "" });
              }
            });
          })
          .catch((err) => callback(err));
      });

      dispatchMinEndDates({ type: "request" });

      parallelLimit<
        { status: number; val: ForecastEndDateResponse; message: string },
        ForecastEndDateFailureResponse
      >(tasks, MAX_NUM_OF_PARALLEL_REQUEST, (err, results) => {
        const listOfFailedPodcasts: string[] = [];
        if (Array.isArray(results)) {
          for (const item of results) {
            if (typeof item === "undefined") continue; // if the result item is undefined, go to next one;

            const { status, message, val } = item;

            const respMinEndDates = val["resultsByShowUUID"];

            for (const [showUUID, value] of Object.entries(respMinEndDates)) {
              const campaignItem = mapShowToCampaignItem[showUUID];

              const showKey = constructEndDateShowKey({
                budgets,
                campaignItem,
              });

              // Cache the results
              if (cache.current) {
                if (typeof cache.current[cacheKey] === "undefined") {
                  cache.current[cacheKey] = {};
                }
                cache.current[cacheKey][showKey] = value;
              }

              if (value.streamulatorErrored) {
                listOfFailedPodcasts.push(publicShows[showUUID].title ?? "");
              }
            }
          }
        }

        const finalErrorMessage = `The inventory of ${listOfFailedPodcasts.length > 1 ? "these podcasts" : "this podcast"} cannot be predicted: ${listOfFailedPodcasts.join(", ")}. Please remove ${listOfFailedPodcasts.length > 1 ? "these" : "this"} podcast from the list to continue or size your campaigns manually.`;

        if (listOfFailedPodcasts.length > 0) {
          dispatch(showWarning(finalErrorMessage, 10000));
        }

        /**
         *  Build response from cached values. Cache  will have any podcasts streamulator estimate that succeeded,
         *  even if 1 or more failures occurred in a batch as long as there were successful estimated, it will be
         *  found here.
         */
        const finalMinEndDates = draftItems.reduce(
          (accu, campaignItem) => {
            const { showUUID } = campaignItem;
            const showKey = constructEndDateShowKey({
              budgets,
              campaignItem,
            });
            const cachedVal = (cache.current[cacheKey] ?? {})[showKey];

            accu[showUUID] = { ...cachedVal };

            return accu;
          },
          {} as ForecastEndDateResponse["resultsByShowUUID"]
        );

        // Update final state
        dispatchMinEndDates({
          type: listOfFailedPodcasts.length > 0 ? "failure" : "success", // its a failure state if even 1 podcast (in any batch) fails.
          error: listOfFailedPodcasts.length > 0 ? finalErrorMessage : undefined,
          minEndDates: finalMinEndDates,
        });
      });
    }
  }, [preReqDataIsReady, budgetChangeSignal, startDateChangeSignal, draftItemsChangeSignal]);

  return minEndDatesState;
};

export const useForecastImpressions = ({
  user,
  campaign,
  publicShows,
  draftItems,
  startDate,
  endDate,
}: {
  user: User;
  campaign?: ICampaign;
  publicShows: PublicShowsReduxState;
  draftItems: ICampaignItem[];
  startDate: UnixTimeStamp;
  endDate: UnixTimeStamp;
}) => {
  const dispatch = useDispatchTS();
  const [maxImpressionsState, dispatchMaxImpressions] = useReducer<IMaxImpressionReducer>(
    maxImpressionReducer,
    { isLoading: false, maxImpressions: {}, error: undefined }
  );

  const mapShowToCampaignItem = draftItems?.reduce(
    (accu, curr) => {
      const { showUUID } = curr;

      accu[showUUID] = { ...curr };

      return accu;
    },
    {} as { [showUUID: string]: ICampaignItem }
  );

  /**
   * Enables hook based on V1 campaign.pacing flag or V2 campaign items pacing flag
   */
  const isPacingEnabled = draftItems.some((item) =>
    getCampaignItemField("pacing", { campaignItem: item, campaign })
  );

  /**
   * Need to capture when individual campaign Items update relevant info for impressions endpoint to fire, will do
   * this by grabbing relevant fields and hashing the object.
   */
  const draftItemsChangeSignal = campaign?.isV2
    ? objectHash(
        draftItems
          .sort((a, b) => a.uuid?.localeCompare(b.uuid))
          .reduce(
            (accu, curr) => {
              accu[curr.uuid] = {
                uuid: curr.uuid,
                startDate: getCampaignItemField("startAt", { campaignItem: curr }),
                endDate: getCampaignItemField("endAt", { campaignItem: curr }),
                positions: getCampaignItemField("targetingOptions", { campaignItem: curr }),
                configs: getCampaignItemField("frequencyConfigs", { campaignItem: curr }),
                recentEpisodesOnly: getCampaignItemField("recentEpisodesOnly", {
                  campaignItem: curr,
                }),
                pacing: getCampaignItemField("pacing", { campaignItem: curr }),
              };

              return accu;
            },
            {} as {
              [campaignItemUUID: string]: {
                uuid: string;
                startDate: number;
                endDate: number;
                positions: ICampaignItem["targetingOptions"];
                configs: ICampaignItem["frequencyConfigs"];
                recentEpisodesOnly: boolean;
                pacing: boolean;
              };
            }
          ),
        { algorithm: "sha1" }
      )
    : "";

  // All data needed for starting requests
  const preReqDataIsReady =
    isPacingEnabled &&
    !!user &&
    !!campaign?.targetingOptions &&
    draftItems?.length > 0 &&
    typeof startDate === "number" &&
    typeof endDate === "number";

  const cache = useRef<
    Partial<
      Record<
        string,
        Record<
          string,
          ForecastImpressionResponse["resultsByShowUUID"][keyof ForecastEndDateResponse["resultsByShowUUID"]]
        >
      >
    >
  >({});

  const cacheKey = constructImpressionsCacheKey({ campaign, startDate, endDate });

  useEffect(() => {
    if (preReqDataIsReady && !maxImpressionsState.isLoading && cacheKey) {
      const requests = constructForecastImpressionsRequest({
        campaign,
        draftItems,
        startDate,
        endDate,
        cache: cache.current,
      });

      dispatchMaxImpressions({ type: "request" });

      const tasks = requests.map((request) => (callback: any) => {
        getCampaignForecastImpressions(request, user)
          .then((resp) => {
            return resp?.json().then((val) => {
              if (resp.status !== 200) {
                callback(null, {
                  status: resp.status,
                  val: val.details,
                  message: val.message,
                });
              } else {
                callback(null, { status: resp.status, val, message: "" });
              }
            });
          })
          .catch((err: ForecastImpressionFailureResponse) => {
            callback(err);
          });
      });

      parallelLimit<
        { status: number; val: ForecastImpressionResponse; message: string },
        ForecastImpressionFailureResponse
      >(tasks, MAX_NUM_OF_PARALLEL_REQUEST, (err, results) => {
        const listOfFailedPodcasts: string[] = [];

        if (Array.isArray(results)) {
          // iterate over the results array, which is an array of streamulator forecast impressions responses
          for (const item of results) {
            if (typeof item === "undefined") continue; // if the result item is undefined, go to next one;
            const { status, val, message } = item;
            const resultsByShowUUID = val["resultsByShowUUID"];

            for (const [showUUID, value] of Object.entries(resultsByShowUUID)) {
              const campaignItem = mapShowToCampaignItem[showUUID];
              const showKey = constructImpressionsShowKey({
                campaignItem,
              });

              // cache the values
              if (cache.current) {
                if (typeof cache.current[cacheKey] === "undefined") {
                  cache.current[cacheKey] = {};
                }
                cache.current[cacheKey][showKey] = value;
              }

              if (value.streamulatorErrored) {
                listOfFailedPodcasts.push(publicShows[showUUID].title ?? "");
              }
            }
          }
        }

        const finalErrorMessage = `The inventory of ${listOfFailedPodcasts.length > 1 ? "these podcasts" : "this podcast"} cannot be predicted: ${listOfFailedPodcasts.join(", ")}. Please remove ${listOfFailedPodcasts.length > 1 ? "these" : "this"} podcast from the list to continue or size your campaigns manually.`;

        if (listOfFailedPodcasts.length > 0) {
          dispatch(showWarning(finalErrorMessage, 10000));
        }

        /**
         *  Build response from cached values. Cache  will have any podcasts streamulator estimate that succeeded,
         *  even if 1 or more failures occurred in a batch as long as there were successful estimated, it will be
         *  found here.
         */
        const finalMaxImpressions = draftItems.reduce(
          (accu, campaignItem) => {
            const { showUUID } = campaignItem;
            const showKey = constructImpressionsShowKey({
              campaignItem,
            });
            const cachedVal = (cache.current[cacheKey] ?? {})[showKey];

            accu[showUUID] = { ...cachedVal };

            return accu;
          },
          {} as ForecastImpressionResponse["resultsByShowUUID"]
        );

        // Update final state
        dispatchMaxImpressions({
          type: listOfFailedPodcasts.length > 0 ? "failure" : "success", // its a failure state if even 1 podcast (in any batch) fails.
          error: listOfFailedPodcasts.length > 0 ? finalErrorMessage : undefined,
          maxImpressions: convertRespToImpressionsMap({
            resultsByShowUUID: finalMaxImpressions,
          }),
        });
      });
    }
  }, [startDate, endDate, preReqDataIsReady, draftItems?.length, draftItemsChangeSignal]);

  return maxImpressionsState;
};
