import dayjs from "dayjs";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SendVerificationEmailManager } from "src/action_managers/email_verification";
import { fetchSubscriptionForUser } from "src/action_managers/subscriptions";
import { permissionTypes } from "src/constants/permission_roles";
import UserRoles from "src/constants/roles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { LOCAL_STORAGE_KEYS, useLocalStorage } from "src/hooks/useLocalStorage";
import { isOrgOwner, useHasAtLeastOnePermitted } from "src/lib/permissions";
import { userHasRole } from "src/lib/user";
import WarningVerifyEmail from "./warning_verify_email";
import WarningVerifyPhone from "./warning_verify_phone";

const MONTHS_UNTIL_LAST_DISMISSED = 6;

export default function WarningAlert() {
  const history = useHistory();
  const { location } = history;
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);
  const { hideWarningBar } = useSelectorTS((state) => state.app);
  const { subscriptions = {} } = useSelectorTS((state) => state.subscriptions);
  const permissionState = useSelectorTS((state) => state.permissions);
  const permittedForAdPlatform = useHasAtLeastOnePermitted(permissionTypes.editRap);

  const [dismissedWarningTimestamps, setDismissedWarningTimestamps] = useLocalStorage(
    LOCAL_STORAGE_KEYS.dismissed_warning_ts_by_credentialUUID,
    {}
  );
  const lastDateDismissed =
    user &&
    dismissedWarningTimestamps[user.credentialUUID as keyof typeof dismissedWarningTimestamps];
  const isWithinSixMonthsDismissed =
    lastDateDismissed &&
    dayjs().diff(dayjs(lastDateDismissed), "month") < MONTHS_UNTIL_LAST_DISMISSED;

  useEffect(() => {
    if (user && userHasRole(user, UserRoles.Sponsor)) {
      dispatch(fetchSubscriptionForUser(user.uuid));
    }
  }, [user]);

  const shouldRenderVerifyEmail = () => {
    if (user.hasVerifiedEmail) return false;
    if (!isOrgOwner(user, permissionState)) return false;
    if (userHasRole(user, UserRoles.Sponsor)) {
      if (!subscriptions || Object.keys(subscriptions).length === 0) return false;
    }
    return true;
  };

  const handleSendVerificationEmail = () => {
    dispatch(new SendVerificationEmailManager({ user }).run());
  };

  const shouldRenderVerifyPhone = () => {
    if (!location || !location.pathname.startsWith("/ad-platform")) return false;
    if (!permittedForAdPlatform) return false;
    if (isWithinSixMonthsDismissed) return false;
    if (user.phoneNumber) return false;
    return true;
  };

  const handleAddPhoneNumber = () => {
    history.push("/account/profile?phone=true");
  };

  const handleDismissPhoneNumber = () => {
    if (!isWithinSixMonthsDismissed) {
      setDismissedWarningTimestamps({
        ...(dismissedWarningTimestamps as Record<string, string>),
        [user.credentialUUID]: new Date().toISOString(),
      });
    }
  };

  if (!user) return null;
  if (hideWarningBar) return null;
  return (
    <>
      {shouldRenderVerifyEmail() && <WarningVerifyEmail onClick={handleSendVerificationEmail} />}
      {shouldRenderVerifyPhone() && (
        <WarningVerifyPhone onClick={handleAddPhoneNumber} onClose={handleDismissPhoneNumber} />
      )}
    </>
  );
}
