import { Dispatch, SetStateAction, useEffect } from "react";
import {
  RawData,
  ReportingPageState,
  createCacheKeyFromState,
  getTotalInsertionsFromData,
  getTotalPodcastsFromData,
  getTotalSpendFromData,
  priceToCent,
  validateKeys,
} from "./utilities";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { StatsRequestFilter } from "../analytics/analyticsUtility";
import {
  InsertionsStatsWBodyActionManager,
  InsertionStatsActionManager,
} from "src/action_managers/stats";
import { Col, Row } from "antd";
import HighlightBox from "src/components/lib/highlight_box/highlight_box";
import { formatMoney } from "redcircle-lib";
import numeral from "numeral";
import { StatsRequestFilterWBody } from "src/api/stats";

export const AdvertisingReportGraphHeader = ({
  reportingState,
  showUUIDsNeeded,
}: {
  reportingState: ReportingPageState;
  showUUIDsNeeded: string[];
}) => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);

  const cacheKeySpend = createCacheKeyFromState({
    state: reportingState,
    prefix: "ATS",
    keys: ["campaignUUIDs"],
  });
  const cacheKeyInsertions = createCacheKeyFromState({
    state: reportingState,
    prefix: "ATI",
    keys: ["campaignUUIDs"],
  });

  const cacheData = useSelectorTS((state) => state.stats.stats);

  const isTotalSpendLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs.includes(cacheKeySpend)
  );
  const isTotalInsertionsLoading = useSelectorTS((state) =>
    state.stats.loadingUUIDs.includes(cacheKeyInsertions)
  );

  const isTotalPodcastsLoading = useSelectorTS((state) => state.campaignItems.isLoading);

  /**
   * Helper Functions
   */

  /**
   * Fetch total spend
   */
  useEffect(() => {
    if (!validateKeys(reportingState, ["campaignUUIDs"])) return; // if state values are valid

    // If cached does not exists then fetch data
    if (!cacheData[cacheKeySpend]) {
      const filters: StatsRequestFilterWBody = {
        isUnique: true,
        timeRange: "allTime",
        interval: "1y",
        campaignUUIDs: reportingState.campaignUUIDs,
        sumterms: "insertion.price",
        bucketTerms: [],
      };

      dispatch(
        new InsertionsStatsWBodyActionManager({
          filters,
          requestID: cacheKeySpend,
        }).run()
      );
    }
  }, [cacheKeySpend]);

  const totalSpend = getTotalSpendFromData(cacheData[cacheKeySpend]);

  /**
   * Fetch total insertions
   */
  useEffect(() => {
    if (!validateKeys(reportingState, ["campaignUUIDs"])) return; // if state values are valid

    // If cached does not exists then fetch data
    if (!cacheData[cacheKeyInsertions]) {
      const filters: StatsRequestFilterWBody = {
        isUnique: true,
        timeRange: "allTime",
        interval: "1y",
        campaignUUIDs: reportingState.campaignUUIDs,
        bucketTerms: [],
      };

      dispatch(
        new InsertionsStatsWBodyActionManager({
          filters,
          requestID: cacheKeyInsertions,
        }).run()
      );
    }
  }, [cacheKeyInsertions]);

  const totalInsertions = getTotalInsertionsFromData(cacheData[cacheKeyInsertions]);

  const totalPodcasts = showUUIDsNeeded.length;
  return (
    <>
      <Row gutter={[16, 16]} justify={"space-evenly"}>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="total spend"
            value={formatMoney(totalSpend)}
            isLoading={isTotalSpendLoading}
          />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="total Insertions"
            value={numeral(totalInsertions).format("0,0")}
            isLoading={isTotalInsertionsLoading}
          />
        </Col>
        <Col xs={12} md={12} lg={8} xl={8}>
          <HighlightBox
            className="bordered"
            title="Total # Podcasts"
            value={numeral(totalPodcasts).format("0,0")}
            isLoading={isTotalPodcastsLoading}
          />
        </Col>
      </Row>
    </>
  );
};
