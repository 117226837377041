import { Form, InputNumber, Popover, Tooltip } from "antd";
import numeral from "numeral";
import { ForwardedRef, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { formatMoney } from "redcircle-lib";
import { Button, COLORS, Modal } from "redcircle-ui";
import { getAverageCPM, getBudget, getDailyImpressions } from "src/lib/campaigns";
import { ICampaignItem } from "src/reducers/campaign_items";
import { IShow } from "redcircle-types";
import { CampaignSchedulerContext } from "./campaign_scheduler_context";

export default function SchedulerBudgetCell({
  campaignItem,
  show,
}: {
  campaignItem: ICampaignItem;
  show: IShow;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // focus on input
  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [showPopover]);

  const { campaign, excludedCampaignItems, budgets, setBudgets, timeline, maxImpressionsState } =
    useContext(CampaignSchedulerContext);
  const isExcluded =
    campaignItem && excludedCampaignItems.map((item) => item.uuid).includes(campaignItem.uuid);

  const cpm = getAverageCPM({ show, campaign, campaignItem });
  const dailyImpressions = getDailyImpressions(show);
  const originalBudget = getBudget({
    cpm,
    dailyImpressions,
    startDate: timeline?.[0]?.unix(),
    endDate: timeline?.[1]?.unix(),
  });
  const budget =
    campaignItem.uuid && budgets[campaignItem.uuid] ? budgets[campaignItem.uuid] : originalBudget;

  const maxImpressionsForShow = maxImpressionsState.maxImpressions?.[campaignItem.showUUID];
  const max =
    maxImpressionsForShow?.impressions > 0 ? (maxImpressionsForShow.impressions / 1000) * cpm : 0;
  const showMaxLimit =
    campaign?.pacing &&
    typeof max === "number" &&
    max >= 0 &&
    typeof budget === "number" &&
    budget >= 0;
  const isTooHigh = showMaxLimit && budget > max;

  const handleSubmitBudget = (value: number) => {
    setBudgets({ ...budgets, [campaignItem.uuid]: value });
  };

  const handleResetBudget = () => {
    setBudgets({ ...budgets, [campaignItem.uuid]: originalBudget });
  };

  return (
    <Popover
      open={showPopover}
      content={
        <PopoverContent
          onClose={() => setShowPopover(false)}
          budget={budget}
          onSubmit={handleSubmitBudget}
          onReset={handleResetBudget}
          originalBudget={originalBudget}
          ref={inputRef}
          max={showMaxLimit ? max : undefined}
        />
      }
      trigger="click"
      onOpenChange={(open) => setShowPopover(open)}
      overlayInnerStyle={{ padding: 0 }}>
      <Button type="secondary" size="small" disabled={isExcluded}>
        {numeral(budget / 100).format("$0,0.00")}
        {isTooHigh && <AiFillExclamationCircle className="m-lxxxs" color={COLORS.COLOR_WARNING} />}
      </Button>
    </Popover>
  );
}

const PopoverContent = forwardRef(function PopoverContent(
  {
    onClose,
    onSubmit,
    onReset,
    budget,
    originalBudget,
    max,
  }: {
    onClose: () => void;
    onSubmit: (value: number) => void;
    onReset: () => void;
    budget: number;
    originalBudget: number;
    max?: number;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const [value, setValue] = useState<number | null>(null);
  const isTooHigh =
    typeof max === "number" && max >= 0 && typeof value === "number" && value >= 0
      ? value > max / 100
      : false;

  useEffect(() => {
    if (typeof budget === "number") {
      setValue(budget / 100);
    }
  }, [budget]);

  const handleReset = () => {
    onReset();
    onClose();
  };

  const handleSubmit = () => {
    if (value === null) return;
    onSubmit(Math.round(value * 100));
    onClose();
  };

  return (
    <Form>
      <div className="p-axs flex-column-container">
        <span>
          <h5>Budget</h5>
          {typeof max === "number" && <span> Estimated Maximum: {formatMoney(max / 100)}</span>}
        </span>
        <div className="flex-row-container align-center m-txxxs">
          <InputNumber
            ref={ref}
            value={value}
            onChange={(value) => setValue(value)}
            prefix="$"
            size="large"
            precision={2}
            min={0.01}
            style={{ width: "100%" }}
            status={isTooHigh ? "warning" : undefined}
          />
          {isTooHigh && (
            <AiFillExclamationCircle className="m-lxxxs" color={COLORS.COLOR_WARNING} />
          )}
        </div>
      </div>
      <Modal.Footer className="p-axs">
        <Button type="link" className="p-a0" size="small" onClick={onClose}>
          Cancel
        </Button>
        <div className="flex-row-container align-center">
          {originalBudget !== budget && (
            <Button type="link" className="p-a0" size="small" onClick={handleReset}>
              Reset
            </Button>
          )}
          <Tooltip
            title={
              isTooHigh
                ? "This budget exceeds the estimated maximum budget for your timeline. It is unlikely this budget will complete for dates you've selected."
                : undefined
            }
            placement="bottom">
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="m-lxxs"
              disabled={value === null || value === 0}
              onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </div>
      </Modal.Footer>
    </Form>
  );
});
