import { RightOutlined } from "@ant-design/icons";
import { Card, Collapse, CollapseProps, Spin, Tabs, TabsProps } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment-timezone";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "redcircle-ui";
import { deleteScript } from "src/action_managers/script";
import LoadingButton from "src/components/forms/loading_button/loading_button";
import RCButton from "src/components/lib/button/button";
import ContextMenu from "src/components/lib/context_menu/context_menu";
import LoadingImage from "src/components/lib/image/image";
import AddScriptModal from "src/components/modals/add_script_modal/add_script_modal";
import { AssignScriptModal } from "src/components/modals/assign_script_modal";
import {
  CampaignStateCreated,
  CampaignStateInProgress,
  ScriptDueDateDaysBeforeStart,
} from "src/constants/campaigns";
import { permissionTypes } from "src/constants/permission_roles";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { isPastDueDate } from "src/lib/campaigns";
import { canAdvertiserAccess } from "src/lib/permissions";
import { pluralize } from "src/lib/strings";
import {
  CampaignPromoCodeType,
  ICampaign,
  ICampaignItem,
  PublicShow,
  Script,
} from "redcircle-types";
import { PublicShowsReduxState } from "src/reducers/public_show";
import "./campaign_page.scss";

type ScriptModalState = {
  visible: boolean;
  scriptUUID?: string | undefined;
  type: "create" | "duplicate" | "edit" | "editAll";
};

// Takes into account phase 2 and phase 3. if script has promo code use that, if not use fallback, which checks for more than 1 show in the script
const promoCodeTypeToFriendly: Record<CampaignPromoCodeType, string> = {
  podcaster_unique: "Each Podcaster should use their own code or vanity URL tag",
  standard: "Promotion code or vanity URL is provided in the script",
  none: "No promotion code or vanity URL",
};

interface ICampaignScriptSummary {
  campaign?: ICampaign;
}

const CampaignScriptSummary = ({ campaign }: ICampaignScriptSummary) => {
  const { uuid: campaignUUID = "" } = campaign || {};

  const {
    campaignItemByCampaignUUID,
    isLoading: campaignItemsIsLoading,
    campaignItems,
  } = useSelectorTS((state) => state?.campaignItems);

  const { scriptsByCampaignUUID, isLoading: scriptsIsLoading } = useSelectorTS(
    (state) => state?.scripts
  );
  const publicShows = useSelectorTS((state) => state.publicShows);
  const { isLoading: publicShowsIsLoading } = publicShows;

  const isLoading = campaignItemsIsLoading || scriptsIsLoading || publicShowsIsLoading;

  const campaignItemsUUIDs =
    campaignUUID?.length > 0 ? campaignItemByCampaignUUID[campaignUUID] : [];
  const scripts = campaignUUID?.length > 0 ? scriptsByCampaignUUID[campaignUUID] : [];

  // prefer to use the due date calculated on the backend when it exists (it
  // gets set on campaign send), but otherwise we estimate the due date based on
  // the campaign item start date
  const scriptDueDate = useSelectorTS((state) => {
    const campaignItem = state?.campaignItems?.campaignItems?.[campaignItemsUUIDs?.[0]];
    if (campaignItem?.scriptDueBy) {
      return moment.unix(campaignItem?.scriptDueBy);
    } else if (campaignItem?.startAt) {
      return moment
        .unix(campaignItem?.startAt)
        .add(-ScriptDueDateDaysBeforeStart, "d")
        .endOf("day");
    }

    return null;
  }); // script Due Date is the same across all campaignItems

  const history = useHistory();

  const [scriptModalProps, setScriptModalProps] = useState<ScriptModalState>({
    visible: false,
    scriptUUID: undefined,
    type: "create",
  });
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const timezone = moment.tz(moment.tz.guess()).zoneAbbr();

  const hasPodcasts = campaignItemsUUIDs?.length > 0;
  const hasScript = scripts?.length > 0;
  const hasPermissionToEdit = canAdvertiserAccess(permissionTypes.editCampaign, campaign);
  const enableEdit =
    campaign?.state === CampaignStateCreated ||
    (campaign?.state === CampaignStateInProgress && !isPastDueDate(scriptDueDate));

  const disabledMessage =
    campaign?.state !== CampaignStateCreated
      ? "Campaign invites have already been sent"
      : "Script due date has passed";

  const handleAddScript = () => {
    setScriptModalProps((prev) => {
      const nextState: ScriptModalState = {
        ...prev,
        visible: true,
        scriptUUID: undefined,
        type: "create",
      };

      return nextState;
    });
  };

  const content = () => {
    if (isLoading) {
      return <Spin />;
    }
    if (!hasPodcasts) {
      return (
        <div className="flex-column-container align-center">
          <p className="fs-15 lh-m p-hm text-subtle flex-column-container">
            You need to invite podcasts to this campaign before you can add a script.
          </p>
          {hasPermissionToEdit && (
            <LoadingButton onClick={() => history.push(`/campaigns/${campaignUUID}/select`)}>
              Add Podcasts
            </LoadingButton>
          )}
        </div>
      );
    }

    if (!hasScript) {
      return (
        <div className="flex-column-container align-center fs-15 text-subtle">
          <p className="m-bxxs">
            You must add a script to your campaign before the script due date.
          </p>
          <p className="m-bxxs">
            Any podcasts that do not have a script assigned to them by the due date will be removed
            from this campaign.
          </p>
          {scriptDueDate && (
            <p>
              Script Due Date:{" "}
              <strong>
                {scriptDueDate.format(`M/D/YY hh:mm A`)} {timezone}
              </strong>
            </p>
          )}
          {hasPermissionToEdit && (
            <LoadingButton
              disabled={!enableEdit}
              disabledMessage={!enableEdit && disabledMessage}
              onClick={() => setVisible(true)}>
              Add Script
            </LoadingButton>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="flex-row-container align-center justify-start p-vxxs">
            <h3 className="m-ra">Scripts</h3>
            <RCButton className="m-rxxs" type="secondary" onClick={handleAddScript}>
              Add Scripts
            </RCButton>
            <RCButton type="primary" onClick={() => setOpenAssignModal(true)}>
              Assign Scripts
            </RCButton>
          </div>
          {scripts?.map((script, index) => {
            const { uuid: scriptUUID } = script;
            const items = campaignItemsUUIDs
              .map((uuid) => campaignItems[uuid])
              .filter((item) => item?.scriptUUID === scriptUUID);

            return (
              <ScriptCard
                key={scriptUUID}
                script={script}
                campaignItems={items}
                publicShows={publicShows}
                setScriptModalProps={setScriptModalProps}
              />
            );
          })}
        </div>
      );
    }
  };

  const setVisible = (boolean: boolean) => {
    setScriptModalProps((prev) => {
      const nextState: ScriptModalState = { ...prev, visible: boolean };
      if (!boolean) {
        nextState.scriptUUID = undefined;
        nextState.type = "create";
      }
      return nextState;
    });
  };

  const bgColor = hasPodcasts && hasScript ? "#ffffff" : "#FAFAFA";

  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{
          textAlign: "center",
          backgroundColor: bgColor,
          borderRadius: "12px",
        }}>
        {content()}
      </Card>
      <AddScriptModal
        visible={scriptModalProps.visible}
        setVisible={setVisible}
        campaignUUID={campaignUUID}
        scriptUUID={scriptModalProps?.scriptUUID}
        type={scriptModalProps?.type}
        enableDelete={true}
      />
      <AssignScriptModal
        open={openAssignModal}
        setOpen={setOpenAssignModal}
        campaignUUID={campaignUUID}
      />
    </>
  );
};

type ScriptTableRecord = ICampaignItem & { publicShow: PublicShow };

const ScriptCard = ({
  script,
  campaignItems,
  publicShows,
  setScriptModalProps,
}: {
  script: Script;
  campaignItems: ICampaignItem[];
  publicShows: PublicShowsReduxState;
  setScriptModalProps: React.Dispatch<React.SetStateAction<ScriptModalState>>;
}) => {
  const { uuid: scriptUUID, name, promoCodeType, readerGuidance, content } = script;
  const dispatch = useDispatchTS();

  const numOfPodcasts = campaignItems?.length;

  // Handlers
  const handleDeleteScript = () => {
    dispatch(deleteScript(scriptUUID)).catch((err) => {
      console.log(err);
    });
  };

  const tableData: ScriptTableRecord[] = campaignItems.map((item) => {
    return { ...item, publicShow: publicShows[item.showUUID] };
  });

  const tableColumns: ColumnsType<ScriptTableRecord> = [
    {
      key: "image",
      title: "",
      width: "10%",
      render: (item: any, record) => {
        return (
          <LoadingImage
            className="m-ha"
            src={record?.publicShow?.imageURL}
            width={40}
            height={40}
            wrapperStyle={{ minWidth: 40 }}
          />
        );
      },
    },
    {
      key: "podcast",
      title: "PODCAST",
      width: "45%",
      render: (item: any, record) => {
        return <span className="line-clamp-2">{record?.publicShow?.title}</span>;
      },
    },
    {
      key: "promoCode",
      title: "PROMO CODE / VANITY URL",
      width: "45%",
      render: (_, record) => {
        const { promoCode } = record;
        const text =
          promoCodeType === CampaignPromoCodeType.PromoCodeTypePodcasterUnique
            ? promoCode
            : promoCodeTypeToFriendly[promoCodeType];
        return <span>{text}</span>;
      },
    },
  ];

  const tabItems: TabsProps["items"] = [
    {
      key: "Podcasts",
      label: `Podcast (${campaignItems?.length})`,
      children: (
        <div>
          {tableData.length === 0 ? (
            <p>No podcasts assigned to this script </p>
          ) : (
            <Table
              dataSource={tableData}
              columns={tableColumns}
              pagination={{ hideOnSinglePage: true }}
            />
          )}
        </div>
      ),
    },
    {
      key: "Details",
      label: "Details",
      children: (
        <div className="flex-column-container align-start">
          <div className="m-bs script-content">
            <h6 className="fs-10 m-bxxxs uppercase color-gray">script or talking points</h6>
            <div
              className="flex-column-container align-start"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="m-bs script-content">
            <h6 className="fs-10 m-bxxxs uppercase color-gray">additional requirements</h6>
            <div
              className="flex-column-container align-start"
              dangerouslySetInnerHTML={{ __html: readerGuidance }}
            />
          </div>
        </div>
      ),
    },
  ];

  const menuItems = {
    "Duplicate Script": {
      title: "Duplicate Script",
      onSelect: () => {
        setScriptModalProps({
          visible: true,
          scriptUUID,
          type: "duplicate",
        });
      },
    },
    "Edit Script": {
      title: "Edit Script",
      onSelect: () =>
        setScriptModalProps({
          visible: true,
          scriptUUID,
          type: "edit",
        }),
    },
    "Delete Script": {
      title: "Delete Script",
      hide: campaignItems?.length > 0,
      onSelect: handleDeleteScript,
    },
  };

  const items: CollapseProps["items"] = [
    {
      key: scriptUUID,
      label: (
        <div className="bold m-ra with-100">{`${name} - ${numOfPodcasts} ${pluralize(
          "Podcast",
          numOfPodcasts
        )}`}</div>
      ),
      children: (
        <div>
          <Tabs defaultActiveKey="Podcasts" items={tabItems} />
        </div>
      ),
      extra: (
        <div onClick={(e) => e.stopPropagation()}>
          <ContextMenu menuItems={menuItems} />
        </div>
      ),
    },
  ];
  return (
    <Card className="m-bxs text-start" bodyStyle={{ padding: 10 }}>
      <Collapse
        className="RC-collapse-v-align"
        ghost
        items={items}
        expandIcon={({ isActive }) => (
          <RightOutlined style={{ fontSize: "18px" }} rotate={isActive ? 90 : 0} />
        )}
        expandIconPosition="end"
      />
    </Card>
  );
};

export default CampaignScriptSummary;
