import { ICampaign } from "src/reducers/campaigns/types";

type TransformedCampaignFields = Pick<ICampaign, "startsAt" | "endsAt" | "estimatedEndsAt">;

export const getCampaignField = (
  field: keyof TransformedCampaignFields,
  { campaign }: { campaign: ICampaign }
) => {
  const mapper = {
    startsAt: campaign?.isV2 ? campaign.startsAtV2 : campaign?.startsAt,
    endsAt: campaign?.isV2 ? campaign.endsAt : campaign?.estimatedEndsAt,
    estimatedEndsAt: campaign?.isV2 ? campaign.endsAt : campaign?.estimatedEndsAt,
  };

  return mapper[field] ?? campaign?.[field];
};
