import { DatePicker, Tooltip } from "antd";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Button, COLORS, SuperDatePicker } from "redcircle-ui";
import { showWarning } from "src/actions/app";
import { useDispatchTS } from "src/hooks/redux-ts";
import { ICampaign } from "redcircle-types";
import {
  cloneDateAs3amEST,
  getCampaignMinimumStartDate,
  getCampaignMinimumStartDateV2,
  initializeCampaignDeadlines,
} from "./campaign_scheduler_utils";

const DeadlineInput = ({
  deadline,
  disabled,
  className,
  onFocus,
  onBlur,
  onChange,
}: {
  deadline: {
    value?: Dayjs;
    isSelecting: boolean;
    label: string;
    smallLabel: string;
    color: string;
  };
  disabled?: boolean;
  className?: string;
  onFocus: (e: any) => void;
  onBlur: (e: any) => void;
  onChange: (date: Dayjs) => void;
}) => {
  return (
    <div className={classNames("flex-column-container", className)}>
      <span className="redcircle-form-label m-b0 lh-18">
        {deadline.label} <span style={{ color: deadline.color }}>({deadline.smallLabel})</span>
      </span>
      <div className="flex-row-container align-center">
        <DatePicker
          className="flex-1"
          value={deadline.value}
          style={{ ...(deadline.isSelecting && { border: `2px solid ${deadline.color}` }) }}
          format="MM/DD/YYYY"
          showNow={false}
          allowClear={false}
          disabled={disabled}
          panelRender={() => null}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(date) => onChange(date)}
        />
        {deadline.value?.isBefore(dayjs()) && (
          <Tooltip title={`Your ${deadline.label} date must be after today.`}>
            <AiFillExclamationCircle color={COLORS.COLOR_ERROR} className="m-lxxs" />
          </Tooltip>
        )}
      </div>
      {deadline.isSelecting && <small className="color-primary">Please select a date</small>}
    </div>
  );
};

const MIN_CAMPAIGN_TIMELINE_IN_DAYS = 30;

/**
 * This component is a wrapper around the RC DatePicker to attach deadlines and form fields
 * While the date can be used in a Form.Item via the value and onChange prop,
 * deadlines are managed via the deadlineValue and onDeadlineChange prop and must be set manually
 */
export default function SchedulerDatePicker({
  isRange,
  value = [dayjs()],
  onChange,
  deadlineValue,
  onDeadlineChange,
  isV2 = true,
}: {
  isRange?: boolean;
  value?: dayjs.Dayjs[];
  onChange?: (value: dayjs.Dayjs[]) => void;
  deadlineValue?: { responseDeadline?: dayjs.Dayjs; assignAudioDeadline?: dayjs.Dayjs };
  onDeadlineChange?: (value: {
    responseDeadline?: dayjs.Dayjs;
    assignAudioDeadline?: dayjs.Dayjs;
  }) => void;
  isV2?: boolean;
}) {
  const dispatch = useDispatchTS();

  const [isAdjustingDeadlines, setIsAdjustingDeadlines] = useState(false);
  // we hold a component state for deadlines
  const [componentDeadlines, setComponentDeadlines] = useState({
    responseDeadline: {
      value: undefined as Dayjs | undefined,
      isSelecting: false,
      label: "Response Due",
      smallLabel: "RD",
      color: COLORS.PRIMARY_COLOR_ACTIVE,
    },
    assignAudioDeadline: {
      value: undefined as Dayjs | undefined,
      isSelecting: false,
      label: "Audio Due",
      smallLabel: "AD",
      color: COLORS.PRIMARY_COLOR_ACTIVE,
    },
  });

  // initialize deadlines from props
  useEffect(() => {
    initializeDeadlines();
  }, [value, deadlineValue]);

  // reset to default deadlines if adjust deadlines is turned off
  useEffect(() => {
    if (!isAdjustingDeadlines) {
      resetDefaultDeadlines(value);
    }
  }, [isAdjustingDeadlines]);

  const initializeDeadlines = () => {
    if (deadlineValue && (deadlineValue.responseDeadline || deadlineValue.assignAudioDeadline)) {
      setComponentDeadlines({
        responseDeadline: {
          ...componentDeadlines.responseDeadline,
          value: deadlineValue.responseDeadline,
          isSelecting: false,
        },
        assignAudioDeadline: {
          ...componentDeadlines.assignAudioDeadline,
          value: deadlineValue.assignAudioDeadline,
          isSelecting: false,
        },
      });
      if (isV2) {
        setIsAdjustingDeadlines(true);
      }
    }
  };

  const resetDefaultDeadlines = (selectedTimeline: [Dayjs, Dayjs] | Dayjs[]) => {
    if (selectedTimeline[0]) {
      const defaultDeadlines = initializeCampaignDeadlines({
        isV2,
        startsAt: selectedTimeline[0].unix(),
        startsAtV2: selectedTimeline[0].unix(),
      } as ICampaign);

      setComponentDeadlines({
        responseDeadline: {
          ...componentDeadlines.responseDeadline,
          value: defaultDeadlines.responseDeadline,
          isSelecting: false,
        },
        assignAudioDeadline: {
          ...componentDeadlines.assignAudioDeadline,
          value: defaultDeadlines.assignAudioDeadline,
          isSelecting: false,
        },
      });
    }
  };

  const handleRenderCell = (date: Dayjs, cell: React.ReactNode, isHovered?: boolean) => {
    for (const deadline of Object.values(componentDeadlines)) {
      if (
        (!deadline.isSelecting && date.isSame(deadline.value, "day")) ||
        (deadline.isSelecting && isHovered)
      ) {
        return (
          <Tooltip title={deadline.label}>
            <div
              style={{
                border: `2px solid ${deadline.color}`,
                borderRadius: 4,
                position: "relative",
              }}>
              <small
                style={{
                  position: "absolute",
                  color: "white",
                  right: -1,
                  top: -3,
                  borderRadius: 99,
                  background: deadline.color,
                }}>
                <strong>{deadline.smallLabel}</strong>
              </small>
              {cell}
            </div>
          </Tooltip>
        );
      }
    }

    return (
      <div style={{ borderTop: `2px solid transparent`, borderBottom: `2px solid transparent` }}>
        {cell}
      </div>
    );
  };

  const handleSelectDate = (
    date: Dayjs,
    context?: {
      selectedTimeline?: [Dayjs, Dayjs] | Dayjs[];
      isValidSelection?: boolean;
      isSelectingStartDate?: boolean;
    }
  ) => {
    const { selectedTimeline, isValidSelection, isSelectingStartDate } = context || {};
    if (isValidSelection && isSelectingStartDate && (!isV2 || !isAdjustingDeadlines)) {
      resetDefaultDeadlines([date]);
    }

    // validate order - response < audio < start < end
    if (componentDeadlines.responseDeadline.isSelecting) {
      if (date.isBefore(dayjs(), "day") || date.isSame(dayjs(), "day")) {
        dispatch(showWarning("Response Due date must be after today"));
      } else if (
        date.isAfter(componentDeadlines.assignAudioDeadline.value, "day") ||
        date.isSame(componentDeadlines.assignAudioDeadline.value, "day")
      ) {
        dispatch(showWarning("Response Due date must be before the Audio Due date"));
      } else {
        setComponentDeadlines({
          ...componentDeadlines,
          responseDeadline: {
            ...componentDeadlines.responseDeadline,
            value: date,
            isSelecting: false,
          },
        });
      }
    }

    if (componentDeadlines.assignAudioDeadline.isSelecting) {
      if (date.isBefore(dayjs(), "day") || date.isSame(dayjs(), "day")) {
        dispatch(showWarning("Audio Due date must be after today"));
      } else if (
        date.isBefore(componentDeadlines.responseDeadline.value, "day") ||
        date.isSame(componentDeadlines.responseDeadline.value, "day")
      ) {
        dispatch(showWarning("Audio Due date must be after the Response Due date"));
      } else if (
        selectedTimeline &&
        (date.isAfter(selectedTimeline[0], "day") || date.isSame(selectedTimeline[0], "day"))
      ) {
        dispatch(showWarning("Audio Due date must be before the campaign start date"));
      } else {
        setComponentDeadlines({
          ...componentDeadlines,
          assignAudioDeadline: {
            ...componentDeadlines.assignAudioDeadline,
            value: date,
            isSelecting: false,
          },
        });
      }
    }
  };

  const handleSubmit = (submitValue: Dayjs[]) => {
    const startDate = cloneDateAs3amEST(submitValue[0]);
    if (submitValue[1]) {
      // if range is provided, set new end date from calendar
      const endDate = cloneDateAs3amEST(submitValue[1]);
      if (onChange) onChange([startDate, endDate]);
    } else if (value[1]) {
      let endDate = cloneDateAs3amEST(value[1]);
      if (value[1].isBefore(startDate, "day") || value[1].isSame(startDate, "day")) {
        endDate = startDate.add(MIN_CAMPAIGN_TIMELINE_IN_DAYS, "day");
      }
      if (onChange) onChange([startDate, endDate]);
    } else {
      if (onChange) onChange([startDate]);
    }

    if (onDeadlineChange) {
      const defaultDeadlines = initializeCampaignDeadlines({
        isV2,
        startsAt: submitValue[0].unix(),
        startsAtV2: submitValue[0].unix(),
      } as ICampaign);

      const newDeadlines: Record<string, Dayjs> = {};
      if (isAdjustingDeadlines && componentDeadlines?.responseDeadline?.value) {
        newDeadlines.responseDeadline = cloneDateAs3amEST(
          componentDeadlines.responseDeadline.value
        );
      } else if (defaultDeadlines.responseDeadline) {
        newDeadlines.responseDeadline = defaultDeadlines.responseDeadline;
      }

      if (isAdjustingDeadlines && componentDeadlines?.assignAudioDeadline?.value) {
        newDeadlines.assignAudioDeadline = cloneDateAs3amEST(
          componentDeadlines.assignAudioDeadline.value
        );
      } else if (defaultDeadlines.assignAudioDeadline) {
        newDeadlines.assignAudioDeadline = defaultDeadlines.assignAudioDeadline;
      }

      onDeadlineChange(newDeadlines);
    }
  };

  const componentValue = useMemo(() => {
    return isRange && value[0] && value[1] ? value : [value[0]];
  }, [value, isRange]);

  return (
    <SuperDatePicker
      value={componentValue}
      onSubmit={handleSubmit}
      renderCell={handleRenderCell} // highlight deadlines
      onSelectDate={handleSelectDate} // date selection for adjusting deadlines
      onClose={() => initializeDeadlines()} // reset deadlines when closing
      disableDate={(date) => {
        // disable past dates
        if (isV2) {
          return date.isBefore(getCampaignMinimumStartDateV2());
        } else {
          return date.isBefore(getCampaignMinimumStartDate());
        }
      }}
      disableNativeSelection={
        // disable date selection when adjusting deadlines
        componentDeadlines.responseDeadline.isSelecting ||
        componentDeadlines.assignAudioDeadline.isSelecting
      }
      renderFooter={({ onSubmit, onClose, onReset, calendarRef, componentTimeline }) => {
        const isDatesValid =
          componentDeadlines &&
          componentTimeline?.[0] &&
          componentTimeline[0].isAfter(componentDeadlines.responseDeadline.value) &&
          componentTimeline[0].isAfter(componentDeadlines.assignAudioDeadline.value) &&
          !componentTimeline[0].isSame(componentDeadlines.responseDeadline.value, "day") &&
          !componentTimeline[0].isSame(componentDeadlines.assignAudioDeadline.value, "day");

        return (
          <div
            className="flex-column-container"
            style={{ borderTop: `1px solid ${COLORS.GRAY_LIGHTER}` }}>
            <div className="flex-column-container p-hxxs p-txs">
              {/* using checkbox in a popover will crash chrome?? */}
              {/* wtf: https://github.com/ant-design/ant-design/issues/48296 */}
              {/* for now... we'll use a button. */}
              {isV2 && (
                <Button
                  type={isAdjustingDeadlines ? "primary" : "secondary"}
                  onClick={() => setIsAdjustingDeadlines(!isAdjustingDeadlines)}>
                  Adjust Deadlines
                </Button>
              )}
              {isAdjustingDeadlines && (
                <div className="flex-row-container justify-between m-txxs">
                  <DeadlineInput
                    deadline={componentDeadlines.responseDeadline}
                    className="flex-1"
                    onChange={handleSelectDate}
                    onFocus={() => {
                      setComponentDeadlines({
                        ...componentDeadlines,
                        responseDeadline: {
                          ...componentDeadlines.responseDeadline,
                          isSelecting: true,
                        },
                        assignAudioDeadline: {
                          ...componentDeadlines.assignAudioDeadline,
                          isSelecting: false,
                        },
                      });
                    }}
                    onBlur={(e) => {
                      const calendarNode = calendarRef.current;
                      if (calendarNode && !calendarNode.contains(e.relatedTarget)) {
                        setComponentDeadlines({
                          ...componentDeadlines,
                          responseDeadline: {
                            ...componentDeadlines.responseDeadline,
                            isSelecting: false,
                          },
                        });
                      }
                    }}
                  />
                  <DeadlineInput
                    deadline={componentDeadlines.assignAudioDeadline}
                    className="m-ls flex-1"
                    onChange={handleSelectDate}
                    onFocus={() => {
                      setComponentDeadlines({
                        ...componentDeadlines,
                        responseDeadline: {
                          ...componentDeadlines.responseDeadline,
                          isSelecting: false,
                        },
                        assignAudioDeadline: {
                          ...componentDeadlines.assignAudioDeadline,
                          isSelecting: true,
                        },
                      });
                    }}
                    onBlur={(e) => {
                      const calendarNode = calendarRef.current;
                      if (calendarNode && !calendarNode.contains(e.relatedTarget)) {
                        setComponentDeadlines({
                          ...componentDeadlines,
                          assignAudioDeadline: {
                            ...componentDeadlines.assignAudioDeadline,
                            isSelecting: false,
                          },
                        });
                      }
                    }}
                  />
                </div>
              )}
              <div className="flex-row-container justify-between m-txs">
                <Button type="link" className="p-a0" onClick={onClose}>
                  Cancel
                </Button>
                <div className="flex-row-container align-center">
                  <Button
                    type="link"
                    className="m-rxs"
                    onClick={() => {
                      onReset(componentValue);
                      initializeDeadlines();
                    }}>
                    Reset
                  </Button>
                  <Tooltip
                    title={
                      !isDatesValid
                        ? "Your dates are invalid. Please make sure that response date and audio due date are before the starting date."
                        : ""
                    }>
                    <Button type="primary" onClick={onSubmit} disabled={!onSubmit || !isDatesValid}>
                      Set Timeline
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
