import Moment from "moment-timezone";
import React from "react";
import { showSuccess } from "src/actions/app";
import Button from "src/components/lib/button";
import ClipboardAction from "src/components/lib/clipboard-action";
import MediaPlayer from "src/components/lib/media_player";
import { SummaryPageSectionWrapper } from "src/components/lib/summary-page-sections/summary-page-sections";
import {
  CampaignItemStateAccepted,
  CampaignItemStateAudioSwapRequested,
  CampaignItemStateAwaitingAudio,
  CampaignItemStateCompleted,
  CampaignItemStateNeedsScript,
  CampaignItemStatePaused,
  CampaignItemStateRunning,
  CampaignStyleHostRead,
} from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { ICampaign } from "redcircle-types";
import { ICampaignItem } from "src/reducers/campaign_items";

export default function ShowCampaignMedia({
  campaign,
  campaignItem,
  showAudioSwapWarning,
  setAudioSwapModal,
  setAwaitingModal,
  canEditRap,
}: {
  campaign: ICampaign;
  campaignItem: ICampaignItem;
  showAudioSwapWarning: boolean;
  setAudioSwapModal: (arg0: boolean) => any;
  setAwaitingModal: (arg0: boolean) => any;
  canEditRap: boolean;
}) {
  const mediaFiles = useSelectorTS((state) => state.mediaFiles);
  const dispatch = useDispatchTS();

  const showMediaPlayer =
    campaignItem &&
    [
      CampaignItemStateCompleted,
      CampaignItemStateRunning,
      CampaignItemStateAccepted,
      CampaignItemStatePaused,
      CampaignItemStateAudioSwapRequested,
    ].includes(campaignItem.state);

  const mediaFileUUID =
    campaign.style === CampaignStyleHostRead
      ? campaignItem && campaignItem.mediaFileUUID
      : campaign.contentMediaFileUUID;

  const menuItems: Record<string, any> = {
    "Copy Audio File URL": {
      onSelect: () => {
        new ClipboardAction({
          text: mediaFiles && mediaFileUUID && mediaFiles[mediaFileUUID].convertedURL,
          container: document.body,
        });
        dispatch(showSuccess("URL Copied to Clipboard"));
      },
    },
  };
  if (
    campaignItem &&
    [CampaignItemStateRunning, CampaignItemStateAccepted].includes(campaignItem.state) &&
    !showAudioSwapWarning &&
    canEditRap
  ) {
    menuItems["Script & Replace Audio"] = {
      onSelect: () => setAudioSwapModal(true),
    };
  }

  if (
    campaignItem &&
    campaignItem.state == CampaignItemStateNeedsScript &&
    campaignItem.scriptDueBy
  ) {
    return (
      <div className="table-empty-state">
        <span className="text-subtle m-ts m-bs m-rs m-ls">
          Brand will provide the script by{" "}
          <strong>{Moment.unix(campaignItem.scriptDueBy).format("M/D/YY")}</strong>. Please check
          back later.
        </span>
      </div>
    );
  }

  return (
    <SummaryPageSectionWrapper
      title={"Current Advertisement Audio"}
      menuItems={showMediaPlayer ? menuItems : undefined}
      noDivider>
      <div className="show-campaign-media-wrapper flex-column-container">
        {showMediaPlayer && <MediaPlayer mediaFileUUID={mediaFileUUID} />}
        {showAudioSwapWarning && (
          <div className="flex-column-container justify-center align-center text-center p-bs p-txs p-hm">
            <span className="bold lh-s p-hm m-bs">
              The brand found an issue with your ad audio. This campaign is paused until the audio
              is replaced.
            </span>
            <p className="lh-s m-bxs">
              <strong>Note from brand</strong>: {`"${campaignItem?.swapAudioInfo?.reason}"`}
            </p>
            <Button
              type="primary"
              size="large"
              className="p-hm"
              onClick={() => setAudioSwapModal(true)}>
              {"View Script & Replace Audio"}
            </Button>
          </div>
        )}
        {campaignItem.state === CampaignItemStateNeedsScript && (
          <div className="text-center m-ts m-bs">
            This campaign is awaiting a script from the brand.
          </div>
        )}
        {campaignItem.state === CampaignItemStateAwaitingAudio && (
          <div className="text-center m-ts m-bs">
            {campaignItem.uploadAudioBy && (
              <>
                <p className="m-bxxs">The script has been provided by the brand.</p>
                <p>
                  Please upload your audio by{" "}
                  <strong>
                    {Moment.unix(campaignItem.uploadAudioBy).local().format("M/D/YY h:mm A")}{" "}
                    {Moment.tz(Moment.tz.guess()).zoneAbbr()}.
                  </strong>
                </p>
              </>
            )}
            <Button
              type="primary"
              size="large"
              disabled={!canEditRap}
              onClick={() => setAwaitingModal(true)}>
              View Script & Upload Audio
            </Button>
          </div>
        )}

        {/* TODO: LIVE ON: DATE */}
      </div>
    </SummaryPageSectionWrapper>
  );
}
