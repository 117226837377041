import dayjs, { Dayjs } from "dayjs";
import ExcelJS from "exceljs";
import { ICampaignItem } from "redcircle-types";
import { store } from "src";
import { showWarning } from "src/actions/app";
import { getCampaignField } from "src/lib/campaign";
import { UnixTimeStamp } from "src/lib/date";
import { isUUID } from "src/lib/uuid";

export type ReportingPageState = {
  dateRange: [UnixTimeStamp, UnixTimeStamp];
  showUUIDs: string[];
  campaignUUIDs: string[];
  groupBy: "campaignUUID" | "showUUID" | "none";
  interval: "1h" | "1d" | "1w" | "1M" | "1y" | "1q";
  timeZone: "UTC" | Exclude<string, "utc">;
  graphFocus: "insertion" | "spend";
};

export type RawData = {
  date: number;
  count: number;
  pathValues: string[];
}[];

export type AdvertisingGraphDataPoint = {
  date: number;
  value: number;
  showUUID?: string;
  podcastName?: string;
  campaignUUID?: string;
  campaignName?: string;
  brandUUID?: string;
  brandName?: string;
  color: string;
  type: ReportingPageState["groupBy"];
};

export const reportingPageDefaultState: ReportingPageState = {
  dateRange: [dayjs().subtract(90, "days").unix(), dayjs().unix()],
  showUUIDs: [],
  campaignUUIDs: [],
  groupBy: "none",
  interval: "1d",
  timeZone: "UTC",
  graphFocus: "insertion",
};

export const UTC = "UTC";

export const SPEND_DATE_BOUNDARY_START = 1689836400; // approximate date when spend insertion.price prop was added to calculate spend.

/**
 *
 *  Helper functions to validate specific keys from reporting page state
 *
 * @param state
 * @param keys
 * @returns {boolean}
 */
export const validateKeys = (
  state: Partial<ReportingPageState>,
  keys: (keyof ReportingPageState)[] = [
    "dateRange",
    "campaignUUIDs",
    "showUUIDs",
    "graphFocus",
    "groupBy",
    "interval",
    "timeZone",
  ]
) => {
  for (const key of keys) {
    switch (key) {
      case "dateRange":
        if (
          !(
            state &&
            typeof state?.["dateRange"]?.[0] === "number" &&
            typeof state?.["dateRange"]?.[1] === "number" &&
            "dateRange" in state &&
            state?.["dateRange"]?.[0] > 0 &&
            state?.["dateRange"]?.[1] > 0 &&
            state?.["dateRange"]?.[0] < state?.["dateRange"]?.[1]
          )
        ) {
          return false;
        }
        break;
      case "campaignUUIDs":
        if (
          !(
            Array.isArray(state["campaignUUIDs"]) &&
            state["campaignUUIDs"].length > 0 &&
            state["campaignUUIDs"].every((uuid) => isUUID(uuid))
          )
        ) {
          return false;
        }
        break;
      case "showUUIDs":
        if (
          !(
            Array.isArray(state["showUUIDs"]) &&
            state["showUUIDs"].length > 0 &&
            state["showUUIDs"].every((uuid) => isUUID(uuid))
          )
        ) {
          return false;
        }
        break;
      case "interval":
        const allAvailableValues: ReportingPageState["interval"][] = [
          "1h",
          "1d",
          "1w",
          "1M",
          "1q",
          "1y",
        ];

        if (
          !(typeof state["interval"] === "string" && allAvailableValues.includes(state["interval"]))
        ) {
          return false;
        }
        break;
      case "groupBy":
        const groupByValues: ReportingPageState["groupBy"][] = ["campaignUUID", "showUUID", "none"];

        if (!(typeof state["groupBy"] === "string" && groupByValues.includes(state["groupBy"]))) {
          return false;
        }

        break;
      case "graphFocus":
        const graphFocusValues: ReportingPageState["graphFocus"][] = ["insertion", "spend"];

        if (
          !(
            typeof state["graphFocus"] === "string" &&
            graphFocusValues.includes(state["graphFocus"])
          )
        ) {
          return false;
        }
        break;
    }
  }

  return true;
};

export const createCacheKeyFromState = ({
  state,
  keys = [
    "dateRange",
    "showUUIDs",
    "campaignUUIDs",
    "groupBy",
    "interval",
    "timeZone",
    "graphFocus",
  ],
  prefix = "",
}: {
  state: ReportingPageState;
  keys?: (keyof ReportingPageState)[];
  prefix?: string;
}) => {
  let cacheKey = prefix;

  for (const key of keys) {
    switch (key) {
      case "dateRange":
        cacheKey += `_${state[key].join(",")}`;
        break;
      case "campaignUUIDs":
      case "showUUIDs":
        cacheKey += `_${[...state[key]].sort((a, b) => a.localeCompare(b)).join(",")}`;
        break;
      case "groupBy":
      case "graphFocus":
      case "interval":
      case "timeZone":
        cacheKey += `_${state[key]}`;
        break;
    }
  }

  return cacheKey;
};

export const priceToCent = (value: number) => value / (1000 * 1000 * 100);

export const acceptableCampaignItemStates: ICampaignItem["state"][] = [
  "running",
  "paused",
  "accepted",
  "completed",
];

/**
 * Creates a default state for reporting page, takes into account url query parameters
 * @param params
 * @returns {ReportingPageState}
 */
export const createDefaultState = (params: URLSearchParams) => {
  const initialState = { ...reportingPageDefaultState };
  const errors: Partial<Record<keyof ReportingPageState, string>> = {};

  if (params.has("dateRange")) {
    const dateRange = params
      .get("dateRange")
      ?.split(",")
      .map((i) => parseInt(i)) as [number, number];

    if (validateKeys({ dateRange }, ["dateRange"])) {
      initialState["dateRange"] = dateRange;
    } else {
      errors["dateRange"] = "Date range values are incorrectly set in the URL.";
    }
  }

  if (params.has("campaignUUIDs")) {
    const campaignUUIDs = params.get("campaignUUIDs")?.split(",");

    if (Array.isArray(campaignUUIDs) && validateKeys({ campaignUUIDs }, ["campaignUUIDs"])) {
      initialState["campaignUUIDs"] = campaignUUIDs;
    } else {
      errors["campaignUUIDs"] =
        "Campaign UUIDs provided in the URL are incorrect. Please check URL.";
    }
  }

  if (params.has("showUUIDs")) {
    const showUUIDs = params.get("showUUIDs")?.split(",");

    if (Array.isArray(showUUIDs) && validateKeys({ showUUIDs }, ["showUUIDs"])) {
      initialState["showUUIDs"] = showUUIDs;
    } else {
      errors["showUUIDs"] = "Podcast UUIDs provided in the URl are incorrect. Please check URL.";
    }
  }

  /**
   * Ensuring the timezone for UTC is correctly formatted for UTC
   */
  if (params.has("timeZone")) {
    const timeZone = params.get("timeZone");

    if (timeZone === "utc" || timeZone === "") {
      initialState["timeZone"] = UTC;
    }
  }

  const similarKeys = ["groupBy", "interval", "graphFocus"] as const;

  for (const key of similarKeys) {
    if (params.has(key)) {
      const keyValue = params.get(key) as ReportingPageState[typeof key];

      if (typeof keyValue === "string" && validateKeys({ [key]: keyValue }, [key])) {
        initialState[key] = keyValue;
      } else {
        errors[key] = `${key} value provided in the URl is incorrect. Please check URL.`;
      }
    }
  }

  const errs = Object.values(errors);

  if (errs.length > 0) {
    store.dispatch(
      showWarning(`Some values were no correctly captured from URL:\n\n${errs.join("\n")}`, 7000)
    );
  }

  return initialState;
};

/**
 * Aggregates spend to a total value and correct dimensions to cents, (Originally in milli cents ?)
 */
export const getTotalSpendFromData = (
  data: {
    date: number;
    count: number;
    pathValues: string[];
  }[] = []
) => {
  const total = data.reduce((accu, curr) => {
    return accu + curr.count;
  }, 0);

  return priceToCent(total);
};

export const getTotalInsertionsFromData = (
  data: {
    date: number;
    count: number;
    pathValues: string[];
  }[] = []
) => {
  const total = data.reduce((accu, curr) => {
    return accu + curr.count;
  }, 0);

  return total;
};

const getPodcastsFromData = (
  data: {
    date: number;
    count: number;
    pathValues: string[];
  }[] = []
) => {
  const showUUIDs = new Set<string>();

  for (const dataPoint of data) {
    const [_, showUUID] = dataPoint.pathValues;
    showUUIDs.add(showUUID);
  }

  return [...showUUIDs];
};

export const getTotalPodcastsFromData = (
  data: {
    date: number;
    count: number;
    pathValues: string[];
  }[] = []
) => {
  return getPodcastsFromData(data).length;
};

export const exportToExcel = ({
  campaignSpendData,
  campaignInsertionData,
  podcastSpendData,
  podcastInsertionData,
  allSpendData,
  allInsertionData,
  reportingState,
  budgetByCampaignUUID,
  showUUIDsNeeded,
}: {
  campaignSpendData: AdvertisingGraphDataPoint[];
  campaignInsertionData: AdvertisingGraphDataPoint[];
  podcastSpendData: AdvertisingGraphDataPoint[];
  podcastInsertionData: AdvertisingGraphDataPoint[];
  allSpendData: AdvertisingGraphDataPoint[];
  allInsertionData: AdvertisingGraphDataPoint[];
  reportingState: ReportingPageState;
  budgetByCampaignUUID: { [campaignUUID: string]: number };
  showUUIDsNeeded: string[];
}) => {
  // Grabbing Raw data for total spend
  const cacheKeySpend = createCacheKeyFromState({
    state: reportingState,
    prefix: "ATS",
    keys: ["campaignUUIDs"],
  });
  // Grabbing Raw data for total insertions
  const cacheKeyInsertions = createCacheKeyFromState({
    state: reportingState,
    prefix: "ATI",
    keys: ["campaignUUIDs"],
  });

  const totalBudget = Object.values(budgetByCampaignUUID ?? {}).reduce((accu, budget) => {
    return accu + budget;
  }, 0);

  const totalSpend = getTotalSpendFromData(store.getState()?.stats?.stats?.[cacheKeySpend]);
  const totalInsertions = getTotalInsertionsFromData(
    store.getState()?.stats?.stats?.[cacheKeyInsertions]
  );

  // Organize All Insertion Data

  const organizedInsertionData: { [date: number]: number } = {};

  allInsertionData.forEach((dataPoint) => {
    const { date, value } = dataPoint;

    organizedInsertionData[date] = value;
  });

  // Organize Campaign Insertion Data
  const campaignInsertionMapper: {
    [campaignUUID: string]: { total: number; name: string; uuid: string };
  } = {};

  const organizedCampaignInsertionData: { [date: number]: { [campaignUUID: string]: number } } = {};
  campaignInsertionData.forEach(
    (dataPoint) => {
      const { campaignUUID = "", campaignName = "", date, value } = dataPoint;
      if (!organizedCampaignInsertionData[date]) organizedCampaignInsertionData[date] = {};

      if (!organizedCampaignInsertionData[date][campaignUUID])
        organizedCampaignInsertionData[date][campaignUUID] = value;

      if (!organizedCampaignInsertionData[date]["RC_total"])
        organizedCampaignInsertionData[date]["RC_total"] = 0;

      organizedCampaignInsertionData[date]["RC_total"] += value;

      if (!campaignInsertionMapper[campaignUUID])
        campaignInsertionMapper[campaignUUID] = {
          total: 0,
          name: campaignName,
          uuid: campaignUUID,
        };

      campaignInsertionMapper[campaignUUID].total += value;

      return organizedCampaignInsertionData;
    },
    {} as { [date: number]: { [campaignUUID: string]: number } }
  );

  // Organize podcast insertion data
  const podcastInsertionMapper: {
    [showUUID: string]: { total: number; name: string; uuid: string };
  } = {};

  const organizedPodcastInsertionData: { [date: number]: { [showUUID: string]: number } } = {};
  podcastInsertionData.forEach(
    (dataPoint) => {
      const { showUUID = "", podcastName = "", date, value } = dataPoint;
      if (!organizedPodcastInsertionData[date]) organizedPodcastInsertionData[date] = {};

      if (!organizedPodcastInsertionData[date][showUUID])
        organizedPodcastInsertionData[date][showUUID] = value;

      if (!organizedPodcastInsertionData[date]["RC_total"])
        organizedPodcastInsertionData[date]["RC_total"] = 0;

      organizedPodcastInsertionData[date]["RC_total"] += value;

      if (!podcastInsertionMapper[showUUID])
        podcastInsertionMapper[showUUID] = { total: 0, name: podcastName, uuid: showUUID };

      podcastInsertionMapper[showUUID].total += value;

      return organizedPodcastInsertionData;
    },
    {} as { [date: number]: { [showUUID: string]: number } }
  );

  // Organized Spend Data
  const organizedSpendData = allSpendData.reduce(
    (accu, dataPoint) => {
      const { date, value } = dataPoint;
      accu[date] = value;
      return accu;
    },
    {} as { [date: number]: number }
  );

  // Organized campaign spend data
  const campaignSpendMapper: {
    [campaignUUID: string]: { total: number; name: string; uuid: string };
  } = {};

  const organizedCampaignSpendData: { [date: number]: { [campaignUUID: string]: number } } = {};

  campaignSpendData.forEach(
    (dataPoint) => {
      const { campaignUUID = "", campaignName = "", date, value } = dataPoint;
      if (!organizedCampaignSpendData[date]) organizedCampaignSpendData[date] = {};

      if (!organizedCampaignSpendData[date][campaignUUID])
        organizedCampaignSpendData[date][campaignUUID] = value;

      if (!organizedCampaignSpendData[date]["RC_total"])
        organizedCampaignSpendData[date]["RC_total"] = 0;

      organizedCampaignSpendData[date]["RC_total"] += value;

      if (!campaignSpendMapper[campaignUUID])
        campaignSpendMapper[campaignUUID] = {
          total: 0,
          name: campaignName,
          uuid: campaignUUID,
        };

      campaignSpendMapper[campaignUUID].total += value;

      return organizedCampaignSpendData;
    },
    {} as { [date: number]: { [campaignUUID: string]: number } }
  );

  // Organize podcast spend data
  const podcastSpendMapper: {
    [showUUID: string]: { total: number; name: string; uuid: string };
  } = {};

  const organizedPodcastSpendData: { [date: number]: { [showUUID: string]: number } } = {};

  podcastSpendData.forEach(
    (dataPoint) => {
      const { showUUID = "", podcastName = "", date, value } = dataPoint;
      if (!organizedPodcastSpendData[date]) organizedPodcastSpendData[date] = {};

      if (!organizedPodcastSpendData[date][showUUID])
        organizedPodcastSpendData[date][showUUID] = value;

      if (!organizedPodcastSpendData[date]["RC_total"])
        organizedPodcastSpendData[date]["RC_total"] = 0;

      organizedPodcastSpendData[date]["RC_total"] += value;

      if (!podcastSpendMapper[showUUID])
        podcastSpendMapper[showUUID] = { total: 0, name: podcastName, uuid: showUUID };

      podcastSpendMapper[showUUID].total += value;

      return organizedPodcastSpendData;
    },
    {} as { [date: number]: { [showUUID: string]: number } }
  );

  // Add data to excel

  // Creating workbook
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Overall Report");

  sheet.addRow(["SPEND REPORT"]);
  if (typeof totalBudget === "number" && totalBudget > 0) {
    sheet.addRow(["Total Budget", parseFloat(totalBudget.toFixed(2))]);
  }
  sheet.addRow(["Total Spend", parseFloat(totalSpend.toFixed(2))]);
  sheet.addRow(["Total # of Podcasts", showUUIDsNeeded.length]);
  const podcastsInReport =
    reportingState?.groupBy === "showUUID"
      ? reportingState?.showUUIDs?.length
      : showUUIDsNeeded?.length;
  sheet.addRow(["# of Podcasts in Report", podcastsInReport]);

  sheet.addRow([]);

  let spendDates: number[] = [];

  switch (reportingState.groupBy) {
    case "campaignUUID":
      spendDates = Object.keys(organizedCampaignInsertionData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
    case "showUUID":
      spendDates = Object.keys(organizedPodcastInsertionData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
    case "none":
      spendDates = Object.keys(organizedSpendData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
  }

  let spendHeaderRow = [];

  if (reportingState.groupBy !== "none") {
    spendHeaderRow.push(
      `${reportingState.groupBy === "campaignUUID" ? "Campaign" : "Podcast"} ID`,
      `${reportingState.groupBy === "campaignUUID" ? "Campaign" : "Podcast"} Name`
    );
  }
  spendHeaderRow = spendHeaderRow.concat(
    spendDates.map((date) => dayjs(date).format("MM/DD/YYYY"))
  );
  if (reportingState.groupBy !== "none") spendHeaderRow.push("TOTAL");

  sheet.addRow(spendHeaderRow);

  if (reportingState.groupBy !== "none") {
    // when data is grouped need to switch the data set to reuse logic.
    const entityMapper =
      reportingState.groupBy === "campaignUUID" ? campaignSpendMapper : podcastSpendMapper;

    const organizedEntitySpendData =
      reportingState.groupBy === "campaignUUID"
        ? organizedCampaignSpendData
        : organizedPodcastSpendData;

    Object.values(entityMapper)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((entity) => {
        const { uuid, name, total: rowTotal } = entity;

        const rowOfSpendData = spendDates.map((date) => organizedEntitySpendData[date][uuid]);

        sheet.addRow([uuid, name, ...rowOfSpendData, rowTotal]);
      });

    sheet.addRow([]);
    const rowOfColumnSpendTotals = spendDates.map((date) => {
      const total = organizedEntitySpendData[date]?.["RC_total"];
      return parseFloat(total.toFixed(2));
    });

    const calculatedTotalSpend = parseFloat(
      spendDates
        .map((date) => {
          return organizedEntitySpendData[date]?.["RC_total"];
        })
        .reduce((accu, curr) => accu + curr, 0)
        .toFixed(2)
    );

    sheet.addRow(["TOTAL", "", ...rowOfColumnSpendTotals, calculatedTotalSpend]);
  } else {
    const rowOfSpendData = spendDates.map((date) => {
      const total = organizedSpendData[date];
      return parseFloat(total.toFixed(2));
    });
    sheet.addRow(rowOfSpendData);
  }

  sheet.addRow([]);

  // Add Insertions data section

  sheet.addRow(["AD INSERTION REPORT"]);
  sheet.addRow(["Total Insertions", totalInsertions]);
  sheet.addRow([]);

  let insertionDates: number[] = [];

  switch (reportingState.groupBy) {
    case "campaignUUID":
      insertionDates = Object.keys(organizedCampaignInsertionData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
    case "showUUID":
      insertionDates = Object.keys(organizedPodcastInsertionData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
    case "none":
      insertionDates = Object.keys(organizedInsertionData)
        .map((date) => parseInt(date))
        .sort((a, b) => a - b);
      break;
  }

  let headerRow = [];

  if (reportingState.groupBy !== "none") {
    headerRow.push(
      `${reportingState.groupBy === "campaignUUID" ? "Campaign" : "Podcast"} ID`,
      `${reportingState.groupBy === "campaignUUID" ? "Campaign" : "Podcast"} Name`
    );
  }
  headerRow = headerRow.concat(insertionDates.map((date) => dayjs(date).format("MM/DD/YYYY")));

  if (reportingState.groupBy !== "none") headerRow.push("TOTAL");

  sheet.addRow(headerRow);

  if (reportingState.groupBy !== "none") {
    // when data is grouped need to switch the data set to reuse logic.
    const entityMapper =
      reportingState.groupBy === "campaignUUID" ? campaignInsertionMapper : podcastInsertionMapper;

    const organizedEntityInsertionData =
      reportingState.groupBy === "campaignUUID"
        ? organizedCampaignInsertionData
        : organizedPodcastInsertionData;

    Object.values(entityMapper)
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((entity) => {
        const { uuid, name, total: totalInsertions } = entity;

        const rowOfInsertionData = insertionDates.map(
          (date) => organizedEntityInsertionData[date][uuid]
        );

        sheet.addRow([uuid, name, ...rowOfInsertionData, totalInsertions]);
      });

    sheet.addRow([]);
    const rowOfColumnInsertionTotals = insertionDates.map(
      (date) => organizedEntityInsertionData[date]?.["RC_total"]
    );

    const calculatedInsertionTotal = rowOfColumnInsertionTotals.reduce(
      (accu, curr) => accu + curr,
      0
    );

    sheet.addRow(["TOTAL", "", ...rowOfColumnInsertionTotals, calculatedInsertionTotal]);
  } else {
    const rowOfInsertionData = insertionDates.map((date) => organizedInsertionData[date]);
    sheet.addRow(rowOfInsertionData);
  }

  autoWidth(sheet);

  /**
   * Adding Campaign data sheet
   */

  const campaignSheet = workbook.addWorksheet("Campaign Data");
  campaignSheet.addRow(["Date", "Campaign Name", "Campaign ID", "Ad Insertion", "Spend"]);

  const campaignRows = campaignInsertionData.sort((a, b) => {
    const compare = a?.campaignName?.localeCompare(b?.campaignName ?? "") ?? 0;
    if (compare !== 0) return compare;

    return a.date - b.date;
  });

  campaignRows.forEach((dataPoint) => {
    const { date, value, campaignName, campaignUUID } = dataPoint;
    const spend =
      isUUID(campaignUUID) && typeof campaignUUID === "string"
        ? organizedCampaignSpendData[date][campaignUUID]
        : 0;

    campaignSheet.addRow([
      dayjs(date).format("MM/DD/YYYY"),
      campaignName,
      campaignUUID,
      value,
      parseFloat(spend.toFixed(2)),
    ]);
  });

  autoWidth(campaignSheet);

  /**
   * Adding Podcast data sheet
   */

  const podcastSheet = workbook.addWorksheet("Podcast Data");
  podcastSheet.addRow(["Date", "Podcast Name", "Podcast ID", "Ad Insertion", "Spend"]);

  const podcastRows = podcastInsertionData.sort((a, b) => {
    const compare = a?.podcastName?.localeCompare(b?.podcastName ?? "") ?? 0;
    if (compare !== 0) return compare;

    return a.date - b.date;
  });

  podcastRows.forEach((dataPoint) => {
    const { date, value, showUUID, podcastName } = dataPoint;

    const spend =
      isUUID(showUUID) && typeof showUUID === "string"
        ? organizedPodcastSpendData[date][showUUID]
        : 0;
    podcastSheet.addRow([
      dayjs(date).format("MM/DD/YYYY"),
      podcastName,
      showUUID,
      value,
      parseFloat(spend.toFixed(2)),
    ]);
  });

  autoWidth(podcastSheet);

  // Save workbook
  workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      const blob = new Blob([buffer]);

      const encodedUri = window.URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `RedCircle_Report_${dayjs().format("MM_DD_YYYY")}.xlsx`);

      link.click();
    })
    .catch((err) => console.log("Error writing excel export", err));
};

/**
 * Auto-fit columns by width
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
const autoWidth = (worksheet: ExcelJS.Worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    typeof column.eachCell === "function" &&
      column.eachCell({ includeEmpty: true }, (cell) => {
        maxColumnLength = Math.max(
          maxColumnLength,
          minimalWidth,
          cell.value ? cell.value.toString().length : 0
        );
      });
    column.width = maxColumnLength + 2;
  });
};

/**
 * Self contained helper function that calculates the start date of a campaign. Assumption is that the
 * campaign and campaign items are in redux state
 *
 * @param campaignUUID
 * @returns {UnixTimeStamp}
 */
export const calcStartDateForReportPage: (campaignUUID: string) => UnixTimeStamp = (
  campaignUUID
) => {
  const { campaigns } = store.getState()?.campaigns;
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
    store.getState()?.campaignItems;

  const campaign = campaigns[campaignUUID];
  const campaignItems = campaignItemByCampaignUUID[campaignUUID]?.map(
    (campaignItemUUID) => campaignItemsMap[campaignItemUUID]
  );

  const campaignStartsAt = getCampaignField("startsAt", { campaign });
  let startDate = typeof campaignStartsAt === "number" ? campaignStartsAt : dayjs().unix();

  if (Array.isArray(campaignItems)) {
    startDate = campaignItems.reduce((accu, curr) => {
      return accu < curr.startAt ? accu : curr.startAt;
    }, startDate);
  }

  return startDate;
};

/**
 * Self contained helper function that calculates the end date of a campaign. Assumption is that the
 * campaign and campaign items are in redux state
 *
 * @param campaignUUID
 * @returns {UnixTimeStamp}
 */
export const calcEndDateForReportPage: (campaignUUID: string) => UnixTimeStamp = (campaignUUID) => {
  const { campaigns } = store.getState()?.campaigns;
  const { campaignItemByCampaignUUID, campaignItems: campaignItemsMap } =
    store.getState()?.campaignItems;

  const campaign = campaigns[campaignUUID];
  const campaignItems = campaignItemByCampaignUUID[campaignUUID]?.map(
    (campaignItemUUID) => campaignItemsMap[campaignItemUUID]
  );

  const endDate =
    typeof campaign?.estimatedEndsAt === "number" ? campaign?.estimatedEndsAt : dayjs().unix();

  const earliestEndDate = campaignItems?.reduce((accu, curr) => {
    if (typeof curr.completedAt === "number") {
      return accu < curr.completedAt ? accu : curr.completedAt;
    }

    if (campaign.pacing && typeof curr.pacingEstimatedEndAt === "number") {
      return accu < curr.pacingEstimatedEndAt ? accu : curr.pacingEstimatedEndAt;
    }

    return accu < dayjs().unix() ? accu : dayjs().unix();
  }, endDate) as UnixTimeStamp;

  return earliestEndDate > dayjs().unix() ? dayjs().unix() : earliestEndDate;
};

/**
 * Calculate partial new state based on campaign UUIDs selected and their approximate start/end dates
 */
export const calcBestDateRange: (
  campaignUUIDs: ReportingPageState["campaignUUIDs"],
  timeZone: ReportingPageState["timeZone"]
) => [Dayjs, Dayjs] | null = (campaignUUIDs, timeZone) => {
  const startDate = campaignUUIDs.reduce((accu, uuid) => {
    const startDate = calcStartDateForReportPage(uuid);
    return accu < startDate ? accu : startDate;
  }, Infinity);

  const endDate = campaignUUIDs.reduce((accu, uuid) => {
    const endDate = calcEndDateForReportPage(uuid);

    return accu < endDate ? endDate : accu;
  }, -Infinity);

  if (
    typeof startDate === "number" &&
    typeof endDate === "number" &&
    startDate !== Infinity &&
    endDate !== -Infinity
  ) {
    const newStart =
      timeZone === UTC
        ? dayjs.unix(startDate).utc().add(-1, "day").startOf("day")
        : dayjs.unix(startDate).add(-1, "day").startOf("day");
    const newEnd =
      timeZone === UTC
        ? dayjs.unix(endDate).utc().add(1, "day").endOf("day")
        : dayjs.unix(endDate).add(1, "day").endOf("day");

    return [newStart, newEnd];
  } else {
    return null;
  }
  return null;
};

/**
 * Based on the date range provided, builds optimized time intervals options to split the data into
 */
export const buildIntervalOptions: (dateRange: [UnixTimeStamp, UnixTimeStamp]) => {
  label: string;
  value: ReportingPageState["interval"];
}[] = (dateRange) => {
  const start = dayjs.unix(dateRange[0]);
  const end = dayjs.unix(dateRange[1]);

  const duration = dayjs.duration(end?.diff(start));

  const hours = duration.asHours();
  const days = duration.asDays();

  if (hours <= 96) {
    return [{ label: "Hour", value: "1h" }];
  } else {
    if (days <= 7) {
      return [
        { label: "Hour", value: "1h" },
        { label: "Day", value: "1d" },
      ];
    } else if (days <= 60) {
      return [
        { label: "Day", value: "1d" },
        { label: "Week", value: "1w" },
      ];
    } else if (days <= 90) {
      return [
        { label: "Day", value: "1d" },
        { label: "Week", value: "1w" },
        { label: "Month", value: "1M" },
      ];
    } else if (days <= 365) {
      return [
        { label: "Week", value: "1w" },
        { label: "Month", value: "1M" },
      ];
    } else {
      return [
        { label: "Month", value: "1M" },
        { label: "Quarter", value: "1q" },
        { label: "Year", value: "1y" },
      ];
    }
  }
};

/**
 * Based on date range provided, provided best default time interval to split the data into.
 */
export const getDefaultInterval: (
  dateRange: [UnixTimeStamp, UnixTimeStamp]
) => ReportingPageState["interval"] = (dateRange) => {
  const start = dayjs.unix(dateRange[0]);
  const end = dayjs.unix(dateRange[1]);

  const duration = dayjs.duration(end?.diff(start));

  const hours = duration.asHours();
  const days = duration.asDays();

  if (hours <= 96) {
    return "1h";
  } else {
    if (days <= 7) {
      return "1d";
    } else if (days <= 60) {
      return "1d";
    } else if (days <= 120) {
      return "1w";
    } else if (days <= 365) {
      return "1M";
    } else {
      return "1q";
    }
  }
};
